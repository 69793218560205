import React, { useMemo } from 'react'
import styles from './navbar.module.scss'
import { LOGIN, SIGNIN } from '../../routes'
import { Link, useLocation } from '@reach/router'
import useMenuStyle from '../../hooks/useMenuStyle'
import { isMobile } from '../../services/auth'

const VisitorMenu = () => {
  const alternative = useMenuStyle()
  const location = useLocation()
  const SignInStyle = useMemo(() => {
    if (alternative) {
      return styles.visitor_btn_signin_alternative
    } else {
      return styles.visitor_btn_signin
    }
  }, [alternative])
  const LogInStyle = useMemo(() => {
    if (alternative) {
      return styles.visitor_btn_login_alternative
    } else {
      return styles.visitor_btn_login
    }
  }, [alternative])

  return (location.pathname === LOGIN || location.pathname === SIGNIN) && isMobile() ? null : (
    <div className={`${styles.visitor_menu} row py-4 py-lg-0`}>
      <div className="col-7  pr-0 order-lg-1">
        <Link to={SIGNIN} className={`${SignInStyle} btn btn-block`}>
          New Account
          <span className={styles.visitor_icon}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M21,10.5H20v-1a1,1,0,0,0-2,0v1H17a1,1,0,0,0,0,2h1v1a1,1,0,0,0,2,0v-1h1a1,1,0,0,0,0-2Zm-7.7,1.72A4.92,4.92,0,0,0,15,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,2,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,13.3,12.22ZM10,11.5a3,3,0,1,1,3-3A3,3,0,0,1,10,11.5Z" />
            </svg>
          </span>
        </Link>
      </div>
      <div className="col-5 order-lg-0">
        <Link to={LOGIN} className={`${LogInStyle} btn btn-block`}>
          Login
          <span className={styles.visitor_icon}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z" />
            </svg>
          </span>
        </Link>
      </div>
    </div>
  )
}

export default VisitorMenu
