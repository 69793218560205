const { GraphQLClient } = require('graphql-request')
const { SiteClient } = require('datocms-client')

const ENV = process.env.GATSBY_DATO_ENVIROMENT
const API_TOKEN = process.env.GATSBY_DATO_FULL_ACCESS_TOKEN

module.exports.client = new SiteClient(API_TOKEN, {
  environment: ENV
})

module.exports.request = function ({ query, variables, preview }) {
  const URL = `https://graphql.datocms.com/environments/${ENV}`
  const endpoint = preview ? `${URL}/preview` : URL
  const client = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${API_TOKEN}`
    }
  })
  return client.request(query, variables)
}
