import React, { useContext } from 'react'
import UserContext from '../../context/userContext'
import VisitorMenu from './visitorMenu'
import AuthMenu from './AuthMenu'

const NavBar = () => {
  const { profile: user } = useContext(UserContext)

  return (
    <div className="container-lg container-fluid bottom-nav">
      <div className="floating-menu">{user ? <AuthMenu /> : <VisitorMenu />}</div>
    </div>
  )
}

export default NavBar
