import React from 'react'
import logo from '../../assets/uthrulogo_purpleBG.png'
import styles from './footer.module.scss'
import { Link } from '@reach/router'
import { AGENT, DISCLAIMER, INDEX, PRIVACY, TERMS } from '../../routes'

const Footer = () => (
  <div className={`bg-secondary pt-4 pb-3 ${styles.footer} pt-lg-5`}>
    <div className="container">
      <div className="row align-items-lg-end">
        <div className="col-12 col-lg-6">
          <div className="row align-items-center mb-4">
            <div className="col-6 mb-lg-4">
              <Link to={INDEX}>
                <img src={logo} alt="Logo" width={65} />
              </Link>
            </div>
            <div className="col-4 pl-0 ml-auto text-left">
              <p className="d-lg-none">
                <span className="small font-weight-light">Contact us at</span> <br />
                <a href="mailto:hello@uthru.io" className="font-weight-normal">
                  hello@uthru.io
                </a>
              </p>
            </div>
          </div>
          <div className="row align-items-lg-end">
            <div className="col-lg-4 col-12">
              <p className="d-lg-block d-none">
                <span className="small font-weight-light">Contact us at</span> <br />
                <a href="mailto:hello@uthru.io" className="font-weight-normal">
                  hello@uthru.io
                </a>
              </p>
            </div>
            <div className="col-lg-8 col-12">
              <ul className="list-unstyled mb-4 d-lg-flex justify-content-between mb-lg-0">
                <li>
                  <Link to={DISCLAIMER}>Disclaimer</Link>
                </li>
                <li>
                  <Link to={TERMS}>Terms of Service</Link>
                </li>
                <li>
                  <Link to={PRIVACY}>Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 text-lg-right text-center">
          <div className="">
            <p className="font-weight-normal">
              Are you a real estate agent? List your properties with us to save time and reduce overheads.
            </p>
            <p>
              <Link className={`btn ${styles.footer_btn}`} to={AGENT}>
                List with Uthru
              </Link>
            </p>
          </div>
          <div className="">
            <p>© Copyright 2020 U Thru Pty Limited</p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
