import firebase from 'firebase/app'

// Add the Firebase products that you want to use
import 'firebase/auth'
import { isBrowser } from './auth'

const PROJECT_ID = process.env.GATSBY_FIREBASE_PROJECT_ID
const API_KEY = process.env.GATSBY_FIREBASE_API_KEY
const SENDER_ID = process.env.GATSBY_FIREBASE_SENDER_ID
const APP_ID = process.env.GATSBY_FIREBASE_APP_ID

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: `${PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${PROJECT_ID}.firebaseio.com`,
  projectId: `${PROJECT_ID}`,
  storageBucket: `${PROJECT_ID}.appspot.com`,
  messagingSenderId: SENDER_ID,
  appId: APP_ID
}

let instance = null

// Initialize Firebase
export const app = () => {
  if (!isBrowser()) return null
  if (instance) return instance
  instance = firebase.initializeApp(firebaseConfig)
  return instance
}

export const googleAuthProvider = () => {
  if (!instance) return null
  return new firebase.auth.GoogleAuthProvider()
}

export const emailCredential = (email, password) => {
  if (!instance) return null
  return new firebase.auth.EmailAuthProvider.credential(email, password)
}

export const phoneAuthProvider = () => {
  if (!instance) return null
  return new firebase.auth.PhoneAuthProvider()
}

export const captchaVerifier = element => {
  if (!instance) return null
  return new firebase.auth.RecaptchaVerifier(element, {
    'size': 'invisible',
    'callback': function () {
      // console.log('[recaptchaVerifier - response]', response)
      // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
    }
  })
}

export const phoneCredential = (verificationId, verificationCode) => {
  if (!instance) return null
  return firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode)
}

export const multiFactor = cred => {
  if (!instance) return null
  return firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
}

export const sendVerificationCode = async (phoneInfoOptions, captcha) => {
  // console.log("[phoneInfoOptions]", phoneInfoOptions)
  if (isBrowser()) {
    return await phoneAuthProvider().verifyPhoneNumber(phoneInfoOptions, captcha)
  }
}

export const verifyCode = async (verificationId, code) => {
  const cred = phoneCredential(verificationId, code)
  return multiFactor(cred)
}
