const APP = '/app'
export const CHECKOUT = APP + '/checkout/'
export const MY_INSPECTIONS = APP + '/inspections/'
export const SHORTLIST = APP + '/shortlist/'
export const PROPERTY = APP + '/property/'
export const DASHBOARD = APP + '/properties/'
export const VERIFICATION = APP + '/verification'
export const THANKS = APP + '/thanks/'
export const BUYER = '/buyersrenters/'
export const AGENT = '/agents/'
export const CART_EMPTY = APP + '/cartempty'
export const ACCOUNT = APP + '/account'
export const LOGIN = APP + '/login/'
export const SIGNIN = APP + '/signin/'
export const FORGOT_PASSWORD = APP + '/forgot-password/'
export const DISCLAIMER = '/disclaimer'
export const TERMS = '/terms/'
export const PRIVACY = '/privacy/'
export const MFA_SETUP = APP + '/mfa-setup/'
export const INDEX = '/'
