import React, { useEffect, useState } from 'react'
import { getNews } from '../../requests/newsHttp'
import $ from 'jquery'
import { getHideAlert, setHideAlertOnSession } from '../../services/auth'

const styles = { top: '0', zIndex: 99999 }

const News = () => {
  const [hideAlert, setHideAlert] = useState(getHideAlert())
  const [news, setNews] = useState({})
  useEffect(() => {
    const fetchNews = async () => {
      const { newsPage } = await getNews()
      setNews(newsPage)
    }
    fetchNews()
  }, [])

  const handleHideAlert = () => {
    $('.alert').alert('close')
    setHideAlertOnSession()
    setHideAlert(true)
  }
  const handleKeyDown = event => {
    if (event.key === ' ' || event.key === 'Enter' || event.key === 'Spacebar') {
      event.preventDefault()
      handleHideAlert()
    }
  }
  return news.message && news.showMessage ? (
    <div
      className={`bg-secondary text-white py-3 px-0 alert rounded-0 position-sticky mb-0 text-left text-lg-center ${
        !hideAlert ? 'd-block' : 'd-none'
      }`}
      style={styles}
      role="alert"
    >
      <div className="container">
        <div
          className="row"
          onClick={handleHideAlert}
          tabIndex={-1}
          onKeyDown={handleKeyDown}
          role={'button'}
          title="Click to hide"
        >
          <div className="col-12">
            <button type="button" className="close text-white" onClick={handleHideAlert} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="col-12">
            <div dangerouslySetInnerHTML={{ __html: news.message }} />
          </div>
        </div>
      </div>
    </div>
  ) : null
}
export default News
