const { getItemType } = require('./util')
const { client, request } = require('./index')
const { BUYER_TYPE } = require('./modelsID')

const BUYER_QUERY = `query buyerQuery($email: String) {
  buyerCollection(filter: {email: {eq: $email}}) {
    id
    fullName
    email
    phoneNumber
    verified
    digitalId
    mfa
    shortlist {
      id
    }
    cart
    customerId
    paymentIntent
    updatedAt
    createdAt
  }
}`
const SHORTLIST_QUERY = `query shortlistQuery($email: String) {
  buyerCollection(filter: {email: {eq: $email}}) {
    shortlist {
      id
      title
      slug
      bathrooms
      bedrooms
      toilets
      locationAddress {
        number
        street
        addressLine2
        suburb
        postCode
      }
      propertyType
      gallerySection {
        album {
          responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 400, h: 230}) {
            src
          }
        }
      }
    }
  }
}`

/**
 * Creates a new buyer on DatoCMS
 * @param {Object} buyer - Buyer object
 * @param {string} buyer.fullName - Buyer's full name
 * @param {string} buyer.email - Buyer's email
 * @param {string} buyer.firebaseUid - Buyer's firebase uid
 * @param {string} buyer.phoneNumber - Buyer's mobile phone number
 * @return {Object} - Buyer record
 */
module.exports.createUser = async function (buyer) {
  let item = { ...buyer }
  item.verified = false
  item.shortlist = []
  item.cart = JSON.stringify([])
  item.userInfo = JSON.stringify({})
  item.paymentIntent = null
  item.digitalId = false
  item.customerId = null
  item.mfa = true
  const { id } = await getItemType(BUYER_TYPE)
  item.itemType = id
  return client.items.create(item)
}

/**
 * Gets the buyer from DatoCMS based on the firebaseUid
 * @param {string} firebaseUid - buyer's firebase uid
 * @return {{buyerCollection: Object}}
 */
module.exports.getUserByUID = function (firebaseUid) {
  const query = `query buyerQuery($firebaseUid: String) {
    buyerCollection(filter: {firebaseUid: {eq: $firebaseUid}}) {
      id
      fullName
      email
      phoneNumber
      verified
      digitalId
      mfa
      shortlist {
        id
      }
      cart
      customerId
      paymentIntent
      updatedAt
      createdAt
    }
  }`
  return request({ query, variables: { firebaseUid } })
}

/**
 * Gets the buyer from DatoCMS based on the email
 * @param {string} email - Buyer's email
 * @return {{buyerCollection: Object}}
 */
module.exports.getUserByEmail = function (email) {
  return request({ query: BUYER_QUERY, variables: { email } })
}

module.exports.getShortlistByEmail = function (email) {
  return request({ query: SHORTLIST_QUERY, variables: { email } })
}

/**
 * Set the Buyer's shortlist property on DatoCMS
 * @param {string} id - Buyer's id
 * @param {string[]} shortlist - New shortlist value
 * @return {Object} - Buyer record
 */
module.exports.saveToShortList = function (id, shortlist) {
  return client.item.update(id, { shortlist: shortlist })
}
/**
 * Set the Buyer's cart property on DatoCMS
 * @param {string} id - Buyer's id
 * @param {booking[]} cart - New cart value
 * @return {Object} - Buyer record
 */
module.exports.addToCart = function (id, cart) {
  return client.item.update(id, { cart: cart })
}
/**
 * Set the Buyer's Stripe customerId on DatoCMS
 * @param {string} id - Buyer's id
 * @param {string} customerId - Stripe customerId
 * @return {Object} - Buyer record
 */
module.exports.addCustomerId = (id, customerId) => {
  return client.item.update(id, { customerId })
}

/**
 * Set the Buyer's verified property to true on DatoCMS
 * @param {string} id - Buyer's id
 * @return {Object} - Buyer record
 */
module.exports.verifyCustomer = id => {
  return client.item.update(id, { verified: true })
}

/**
 * Set the Buyer's digitalId property to true on DatoCMS and the values for verified and userInfo as well
 * @param {string} id - Buyer's id
 * @param {boolean} verified - Buyer's verified value
 * @param {JSON} userInfo - Buyer's userInfo from DigitalId
 * @return {Object} - Buyer record
 */
module.exports.verifyCustomerOnDigitalID = (id, verified, userInfo) => {
  return client.item.update(id, { digitalId: true, verified, userInfo })
}
/**
 * Set the Buyer's Stripe payment intent  on DatoCMS
 * @param {string} id - Buyer's id
 * @param {string} paymentIntent - Stripe payment Intent
 * @return {Object} - Buyer record
 */
module.exports.addPaymentIntent = (id, paymentIntent) => {
  return client.item.update(id, { paymentIntent })
}

/**
 * Updates a Buyer's fullName, email and phoneNumber on DatoCMS
 * @param {string} id - Buyer's id
 * @param {string} fullName - New full name value
 * @param {string} email - New email value
 * @param {string} phoneNumber - New phone number value
 * @param {boolean} mfa - MFA flag
 * @return {Object} - Buyer record
 */
module.exports.updateBuyer = ({ id, fullName, email, phoneNumber, mfa }) => {
  return client.item.update(id, { fullName, email, phoneNumber, mfa })
}

/**
 * Gets a Buyer by id from DatoCMS
 * @param {string} id - Buyer's id
 * @return {Object} - Buyer record
 */
module.exports.findCustomerById = id => {
  return client.items.find(id, {
    version: 'published'
  })
}

/**
 * Clears the Buyer's cart and paymentIntent property on DatoCMS
 * @param {string} id - Buyer's id
 * @return {Object} - Buyer record
 */
module.exports.checkout = id => {
  return client.item.update(id, {
    cart: JSON.stringify([]),
    verified: true,
    paymentIntent: ''
  })
}

/**
 * Activates the MFA field if an error happens when disabling
 * @param {string} id - Buyer's id
 * @param {boolean} mfa - MFA flab
 * @return {Object} - Buyer record
 */
module.exports.forceMFA = (id, mfa) => {
  return client.item.update(id, {
    mfa
  })
}
