const { request } = require('./index')
/**
 * Gets the news form DatoCMS
 * @return {{newsPage: Object}}
 */
module.exports.getNews = () => {
  const query = `
    query NewsQuery {
      newsPage {
        message(markdown: true)
        showMessage
      }
    }
  `
  return request({ query, variables: {} })
}
