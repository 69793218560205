import { useState } from 'react'
import { useLocation } from '@reach/router'
import { AGENT, BUYER, INDEX } from '../routes'

export default function useMenuStyle() {
  const location = useLocation()
  const [style] = useState(location.pathname === INDEX || location.pathname === AGENT || location.pathname === BUYER)

  return style
}
