import { getUser, handleSignIn, isBrowser } from '../services/auth'
import React, { useEffect, useState } from 'react'
import UserContext from '../context/userContext'
import useFirebase from '../hooks/useFirebase'
import { getUserByUID } from '../requests/buyerHttp'
import { navigate } from 'gatsby'
import { DASHBOARD, MFA_SETUP } from '../routes'

const handleExistingUser = (user, existingUser, setProfile) => {
  const result = { ...existingUser, shortlist: existingUser.shortlist.map(item => item.id) }
  result.multiFactor = user.multiFactor
  setProfile(result)
  handleSignIn(result)
  navigate(DASHBOARD)
}
const handleNewUser = () => {
  navigate(MFA_SETUP)
}
const AuthProvider = ({ children }) => {
  const [profile, setProfile] = useState(getUser())
  const app = useFirebase()

  useEffect(() => {
    let hook
    if (isBrowser() && app) {
      hook = app.auth().onAuthStateChanged(user => {
        const fetchUser = async user => {
          const { buyerCollection: existingUser } = await getUserByUID(user.uid)
          try {
            if (existingUser) {
              handleExistingUser(user, existingUser, setProfile)
            } else {
              handleNewUser(user)
            }
          } catch (e) {
            await app.auth().signOut()
          }
        }
        if (user) {
          fetchUser(user)
        } else {
          setProfile(null)
          handleSignIn(null)
        }
      })
    }
    return () => {
      if (hook) hook()
    }
  }, [app])
  return <UserContext.Provider value={{ profile, setProfile }}>{children}</UserContext.Provider>
}

export default AuthProvider
