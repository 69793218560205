import React, { useContext } from 'react'
import logoWhite from '../../assets/logo-white-1.png'
import logoSalmon from '../../assets/logo-salmon-1.png'
import { Link } from 'gatsby'
import * as Routes from '../../routes'
import { ACCOUNT } from '../icons'
import UserContext from '../../context/userContext'
import { isBrowser } from '../../services/auth'
import useFirebase from '../../hooks/useFirebase'
import useMenuStyle from '../../hooks/useMenuStyle'
import { INDEX } from '../../routes'

const activeStyle = {
  backgroundColor: '#e5a191',
  color: '#fff'
}
const HeaderRenter = () => {
  const { profile: user } = useContext(UserContext)
  const alternative = useMenuStyle()
  const logoIMG = alternative ? logoWhite : logoSalmon
  const app = useFirebase()
  const handleLogout = async () => {
    try {
      if (isBrowser() && app) {
        await app.auth().signOut()
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div className="container py-4 mb-4">
      <Link to={INDEX}>
        <img src={logoIMG} alt="Logo" />
      </Link>
      {user && (
        <div className="dropdown float-right">
          <button
            className="btn dropdown-toggle btn-settings"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <ACCOUNT />
            <span className="sr-only">Menu</span>
          </button>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <Link to={Routes.ACCOUNT} className="dropdown-item" activeStyle={activeStyle}>
              Account
            </Link>
            <div className="dropdown-divider" />
            <button className="dropdown-item" onClick={handleLogout} type="button">
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default HeaderRenter
