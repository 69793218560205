import React, { useContext, useMemo } from 'react'
import { Link } from 'gatsby'
import styles from './navbar.module.scss'
import * as Routes from '../../routes/index'
import userContext from '../../context/userContext'
import useMenuStyle from '../../hooks/useMenuStyle'

const AuthMenu = () => {
  const { profile } = useContext(userContext)
  const hasBookings = profile ? profile.cart.length > 0 : false
  const alternative = useMenuStyle()
  const menuStyle = useMemo(() => {
    if (alternative) {
      return styles.menuLink_alternative
    } else {
      return styles.menuLink
    }
  }, [alternative])
  return (
    <nav className={`${styles.navBar} row navbar`}>
      <div className="d-flex align-content-stretch align-items-stretch col px-0">
        <Link to={Routes.DASHBOARD} className={`${menuStyle} d-none d-lg-inline`} activeClassName={styles.activeStyle}>
          Dashboard
        </Link>
        <Link className={menuStyle} to={Routes.MY_INSPECTIONS} activeClassName={styles.activeStyle}>
          <span className={styles.link_ico}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
              <path d="M12,19a1,1,0,1,0-1-1A1,1,0,0,0,12,19Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,19Zm0-4a1,1,0,1,0-1-1A1,1,0,0,0,17,15Zm-5,0a1,1,0,1,0-1-1A1,1,0,0,0,12,15ZM19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A3,3,0,0,0,2,6V20a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20ZM20,9H4V6A1,1,0,0,1,5,5H6V6A1,1,0,0,0,8,6V5h8V6a1,1,0,0,0,2,0V5h1a1,1,0,0,1,1,1ZM7,15a1,1,0,1,0-1-1A1,1,0,0,0,7,15Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,7,19Z" />
            </svg>
          </span>
          My Inspections
        </Link>
        <Link className={menuStyle} to={Routes.CHECKOUT} activeClassName={styles.activeStyle}>
          <span className={styles.link_ico}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.5,15a3,3,0,0,0-1.9-2.78l1.87-7a1,1,0,0,0-.18-.87A1,1,0,0,0,20.5,4H6.8L6.47,2.74A1,1,0,0,0,5.5,2h-2V4H4.73l2.48,9.26a1,1,0,0,0,1,.74H18.5a1,1,0,0,1,0,2H5.5a1,1,0,0,0,0,2H6.68a3,3,0,1,0,5.64,0h2.36a3,3,0,1,0,5.82,1,2.94,2.94,0,0,0-.4-1.47A3,3,0,0,0,21.5,15Zm-3.91-3H9L7.34,6H19.2ZM9.5,20a1,1,0,1,1,1-1A1,1,0,0,1,9.5,20Zm8,0a1,1,0,1,1,1-1A1,1,0,0,1,17.5,20Z" />
            </svg>
          </span>
          {hasBookings && <span className="notification-badge" />}
          Cart
        </Link>
        <Link className={menuStyle} to={Routes.SHORTLIST} activeClassName={styles.activeStyle}>
          <span className={styles.link_ico}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z" />
            </svg>
          </span>
          Shortlist
        </Link>
      </div>
    </nav>
  )
}
export default AuthMenu
