const { client } = require('./index')

/**
 * Gets the Item type id of a model
 * @param {string} modelID - DatoCMS model id name
 * @return {string}
 */
module.exports.getItemType = modelID => {
  return client.itemTypes.find(modelID)
}
